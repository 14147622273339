import axios from 'axios';
import React, { useRef, useState } from 'react';

// https://yitzchaklayerserver.onrender.com/users
function Contact(props) {
    const [flag, setFlag] = useState(false)

    const nameInputRef = useRef();
    const phoneInputRef = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        await axios.post("https://yitzchaklayerserver.onrender.com/users", {
            name: nameInputRef.current.value,
            phone: phoneInputRef.current.value
        })

        setFlag(true)
    }

    return (
        <div className='Contact' onSubmit={handleSubmit}>
            <h1>צור קשר</h1>
            <form className='form' onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="exampleInputName1" className="form-label">שם פרטי:</label>
                    <input type="text" ref={nameInputRef} className="form-control" id="exampleInputName1" aria-describedby="nameHelp" />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleInputPhone1" className="form-label">מספר טלפון:</label>
                    <input type="text" ref={phoneInputRef} className="form-control" id="exampleInputPhone1" />
                </div>
                <div className='flex'>
                    <button type="submit" className="btn btn-dark">שלח</button>
                </div>
            </form>
            {flag && (
                <p style={{fontSize:"28pt",}}>
                    תודה רבה, נחזור אליכם בהקדם!
                </p>
            )}

        </div>
    );
}

export default Contact;