import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import picArticle from '../../photos/picArticle.JPG';
// import project1_1 from '../../photos/project1_1.png';
import project1_2 from '../../photos/project1_2.png';
import project1_3 from '../../photos/project1_3.png';
import project1_4 from '../../photos/project1_4.png';
import project2_1 from '../../photos/project2_1.jpg';
import project2_2 from '../../photos/project2_2.jpg';
function Article2(props) {
    const [isLargeScreen, setIsLargeScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 600);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const data1 = `פינוי בינוי היא  תכנית להתחדשות עירונית אשר באה לתת מענה הן לרעידות אדמה והן למתקפות טילים (ממ"ד). בישראל קיימות מאות אלפי דירות ישנות אשר נבנו לפני עשרות שנים ואינן עומדות בתקני הבנייה הנוכחיים. לנוכח אתגרי הביטחון בישראל והמצאותה הגאוגרפית על השבר הסורי-אפריקאי, יש צורך דחוף בקידום פרוייקטים מסוג זה. המחוקק קבע תקנות וחוקים שונים כדי לאפשר לבעלי הדירות מעטה הגנה בסיסי והכרחי בדמות תכנית פינוי בינוי.`;
    const data2 = `כדי להוציא לפועל את התכנית יש צורך בהסכמה של שני שליש מבעלי הדירות במתחם. דיירים אשר מסרבים לביצוע התכנית עשויים להיות מוגדרים כ-"דיירים סרבנים" ובהמשך לכך עלולים להיות חשופים לתביעות על ידי יתר הדיירים בגין הנזקים שיגרמו להם.`;
    const data3 = `הליכי התחדשות עירונית בכלל ופינוי בינוי בפרט הינם הליכים מורכבים אשר בסופם הדיירים זוכים לקבלת דירה חדשה בסטנדרט גבוה, החלפת התשתיות הקיימות ושינוי סביבת המגורים שלהם. בשל המורכבות המאפיינת הליכים אלו הם לרוב נמשכים שנים (כיום המגמה היא לקיצור משך ההליך). `;
    const data4 = `חשוב להדגיש כי הדיירים לא צריכים לשלם דבר בשום שלב של ההליך. המימון של הפרויקט כולל תשלום לכל אנשי המקצוע המלווים מטעם הדיירים נעשה על ידי היזם בלבד!`;

    const data5 = `לא אחת, פונים יזמים באופן אקטיבי לדיירים בהצעה לבצע התחדשות עירונית אצלם בבניין/מתחם, תוך
    התעלמות משלבי ההליך התקין. הם ממנים בעצמם את הנציגות ולעיתים גם את עורך הדין מטעם
    הדיירים. במקרה שהדיירים נענים להצעה שכזאת, הם מסתכנים לא רק בניגוד אינטרסים מצד הגורמים
    המלווים (שמאי, עו"ד, מפקח בנייה וכו') שאמורים להבטיח את האינטרסים שלהם בפרוייקט, אלא ממצבים
    עצמם בצד החלש של העסקה. כתוצאה מכך:`;

    const data5li1 = `הדיירים לא יכולים לשלוט בזהות המבצע ו/או באיכות הביצוע.`;
    const data5li2 = `הדיירים לא יקבלו את התמורה המירבית לה היו זכאים אילו היו מקיימים הליך תחרותי.`;
    const data5li3 = `הדיירים לא יכולים לדעת את מצב האיתנות הפיננסית של היזם ומסתכנים בכך שהוא לא יוכל
    לעמוד בהתחייבויותיו ואף יפשוט רגל.`;

    const data6 = `דרך המלך היא בחירת היזם על ידי יציאה למכרז קבלנים/יזמים!`;

    const data7 = `על מנת ליצור בהירות בכל הנוגע להליך ההתחדשות העירונית אצלכם במתחם, להלן שלבי ההליך לפי סדר כרונולוגי:`;

    const dataLi1 = `בחירת נציגות (הסכמה של מינימום 51% מבעלי הדירות בבניין) וחתימה על הסכם עם עו"ד מלווה.    `;
    const dataLi2 = `מינוי בעלי מקצוע מלווים להליך כגון: שמאי, מפקח בנייה וכו'.`;
    const dataLi3 = `יציאה למכרז יזמים/קבלנים ובחירת ההצעה הטובה ביותר במכרז.`;
    const dataLi4 = `ניהול מו"מ מול היזם הנבחר וגיבוש כל ההסכמות לכדי הסכם פינוי בינוי מוסכם בין הצדדים.`;
    const dataLi5 = `חתימה על הסכם פינוי בינוי (עמידה בדרישת מינימום חתימות 67%).`;
    const dataLi6 = `הכנה והגשת תב"ע (תכנית בניין עיר) לאישור הועדה המקומית.`;
    const dataLi7 = `אישור התכנית ע"י הועדה המקומית והגשתה לועדה המחוזית.`;
    const dataLi8 = `אישור התכנית ע"י הועדה המחוזית והפקדתה להתנגדויות.`;
    const dataLi9 = `אישור ופרסום התכנית ברשומות.`;
    const dataLi10 = `הכנה והגשת בקשה להיתר בנייה.`;
    const dataLi11 = `דיון בועדה המקומית לתכנון ובנייה בבקשה להיתר.`;
    const dataLi12 = `החלטת הועדה המקומית לתכנון ובנייה בדבר מתן היתר בתנאים.`;
    const dataLi13 = `מתן הודעות פינוי לדיירים ובחירת הדירות החדשות.`;
    const dataLi14 = `חתימת היזם על הסכם ליווי בנקאי והנפקת ערבויות לדיירים.`;
    const dataLi15 = `תשלום אגרות ע"י היזם וקבלת היתר בנייה.`;
    const dataLi16 = `השלמת פינוי המתחם במלואו (הדיירים מתפנים לשכירות) ותחילת עבודות ביצוע.`;
    const dataLi17 = `סיום עבודות וקבלת מפתח.`;
    const dataLi18 = `שנת בדק.`;
    const dataLi19 = `רישום הדירות על שם הדיירים בטאבו.`;

    // const dataPartTwo1 = `חשוב להדגיש כי הדיירים לא צריכים לשלם דבר בשום שלב של ההליך. המימון של הפרויקט כולל תשלום לכל אנשי המקצוע המלווים מטעם הדיירים נעשה על ידי היזם בלבד!`;
    // const dataPartTwo2 = `כפי שניתן להבין מדובר בהליך מורכב מאוד ולכן ליווי מקצועי ואיכותי הוא קריטי להצלחת הפרוייקט. חשוב מאוד שהוא יבוצע בשקיפות, אמינות, הוגנות ושיוויוניות לכל אורכו על מנת ליצור שיתוף, מעורבות ולהבטיח את האינטרס של הדיירים. `;
    // const dataPartTwo3 = `נציגות הדיירים הינה הגורם המקשר בין המעטפת המקצועית של הפרוייקט לדיירים בכל הקשור למתן אינפורמציה באופן שוטף וקבלת החלטות בצמתים שונים של ההליך (לדוגמה: בחירת אנשי המקצוע המלווים, בחירת היזם ושיקוף הצרכים שיעלו מעת לעת על ידי הדיירים לצוות המקצועי המלווה ומתן פתרונות).`;
    // const dataPartTwo4 = `בשל תפקידה המרכזי, חשוב שהנציגות תורכב מדיירים ראויים, אמינים וזמינים, ותבחר באסיפת דיירים באופן דמוקרטי ובהתראה של שבוע מראש. יצויין כי הנציגות פועלת בהתנדבות מלאה מטעם ולמען הדיירים ועליה לפעול בשקיפות והוגנות לכל אורך הפרוייקט.`;
    // const dataPartTwo5 = `יודגש, כי בחירת אנשי המקצוע המלווים צריכה להעשות לפני פנייה או התקשרות עם היזם כדי למנוע מצב של ניגוד עניינים ועל מנת שאלו ישרתו את האינטרס של הדיירים בלבד! `;
    // const dataPartTwo6 = `לא אחת, פונים יזמים באופן אקטיבי לדיירים בהצעה לבצע התחדשות עירונית אצלם בבניין/מתחם, תוך התעלמות משלבי ההליך התקין. הם ממנים בעצמם את הנציגות ולעיתים גם את עורך הדין מטעם הדיירים. במקרה שהדיירים נענים להצעה שכזאת, הם מסתכנים לא רק בניגוד אינטרסים מצד הגורמים המלווים (שמאי, עו"ד, מפקח בנייה) שאמורים להבטיח את האינטרסים שלהם בפרוייקט, אלא ממצבים עצמם בצד החלש של העסקה. כתוצאה מכך: `;

    // const dataLiPartTwo1 = `הדיירים לא יכולים לשלוט בזהות המבצע ו/או באיכות הביצוע. `;
    // const dataLiPartTwo2 = `הדיירים לא יקבלו את התמורה המירבית לה היו זכאים אילו היו מקיימים הליך תחרותי. `;
    // const dataLiPartTwo3 = `הדיירים לא יכולים לדעת את מצב האיתנות הפיננסית של היזם ומסתכנים בכך שהוא לא יוכל לעמוד בהתחייבויותיו ואף יפשוט רגל.`;

    // const dataPartThree1 = `דרך המלך היא בחירת היזם על ידי יציאה למכרז קבלנים/יזמים!`;
    // const dataPartThree2 = `בצורה זו הדיירים שולטים בהליך כבר מתחילתו ויכולים לדרוש מהיזמים עמידה בתנאי סף לביצוע התחדשות עירונית על פי קריטריונים לבחירתם בליווי אנשי מקצוע מנוסים שיבטיחו את האינטרסים שלהם בלבד. הדיירים מבטיחים לעצמם מיקסום של התמורות והבטחונות להם הם זכאים מחד ואיכות ביצוע בכפוף ללו"ז ידוע מראש (ככל הניתן) מאידך.`;
    // const dataPartThree3 = `לסיכום, שלב ההתארגנות (השלב הראשוני בו בוחרים עו"ד ונציגות מטעם הדיירים) הוא השלב החשוב ביותר בהליך ובמידה רבה קובע את קצב והמשכות הפרוייקט, ולא פחות חשוב, התמורות והבטחונות שיקבלו הדיירים בהסכם הפינוי בינוי מהיזם שיבחר.`;

    const dataPartFour1 = `משרדנו מלווה פרויקטי פינוי בינוי ברחבי הארץ החל מהשלב הראשון, ואנו רואים בבעלי הדירות שותפים מהמעלה הראשונה בהליך.`;
    const dataPartFour2 = `אנו מסייעים לבעלי הדירות בבחירת נציגויות ובהמשך, גם בבחירת גורמי המקצוע המלווים מטעמם (עו"ד, שמאי, מפקח בנייה וכו'). אנו מאפיינים את הצרכים והרצונות של הדיירים ומביאים אותם לפועל כבר בשלב עריכת המכרז. אנו עורכים מכרזים על מנת לאתר את היזם המתאים ביותר למתחם וכך מוודאים בין היתר, כי הוא בעל איתנות פיננסית מחד ובעל ניסיון רלוונטי מאידך. `;
    const dataPartFour3 = `אנו מלווים את הדיירים ומוודאים כי היזם ממלא אחר התחייבויותיו בהסכם הפינוי בינוי לאורך ההליך כולו. `;
    const dataPartFour4 = `בעלי הדירות שותפים לקבלת ההחלטות במעלה הדרך וכך מסייעים בקידום הפרוייקט בצורה המיטבית ביותר ולשביעות רצון הדייירים. משרדנו מתמחה בליווי וארגון דיירים בהליכי פינוי בינוי החל מהרגע הראשון ועד לקבלת מפתח לדירה החדשה.`;


    return (
        <div className='Article'>
            <Link to='/'>
                <button type="button" class="btn btn-dark" style={{ float: "right" }}>חזרה לדף הבית</button>
            </Link>

            <br />

            <h1>
                התחדשות עירונית
            </h1>
            <br />
            <p style={{ fontSize: "18pt" }}>
                <h3>מה זה פינוי בינוי ולמה זה נחוץ?</h3>
                {data1} <br /> <br />
                {data2} <br /> <br />
                {data3} <br /> <br />
                {data4} <br /> <br />
                <h3>אז ממה להזהר?</h3>
                {data5} <br /> <br />
            </p>
            <ul>
                <li>{data5li1}</li>
                <li>{data5li2}</li>
                <li>{data5li3}</li>
            </ul>
            <p style={{ fontSize: "28pt" }}>
                <br />
                <u>   {data6} </u><br /> <br />
            </p>

            {/* <img src={picArticle} alt="picArticle" width="1000pt" /> */}

            {isLargeScreen ? (
                <img src={picArticle} alt="logo" width='100%' height='100%' />
            ) : (
                <img src={picArticle} alt="logo" width='100%' height='100%' />
            )}


            <br />
            <br />

            <p style={{ fontSize: "18pt" }}>
                <h3>שלבי ההליך</h3>
                {data7} <br /> <br />
            </p>
            <ul>
                <li>{dataLi1}</li>
                <li>{dataLi2}</li>
                <li>{dataLi3}</li>
                <li>{dataLi4}</li>
                <li>{dataLi5}</li>
                <li>{dataLi6}</li>
                <li>{dataLi7}</li>
                <li>{dataLi8}</li>
                <li>{dataLi9}</li>
                <li>{dataLi10}</li>
                <li>{dataLi11}</li>
                <li>{dataLi12}</li>
                <li>{dataLi13}</li>
                <li>{dataLi14}</li>
                <li>{dataLi15}</li>
                <li>{dataLi16}</li>
                <li>{dataLi17}</li>
                <li>{dataLi18}</li>
                <li>{dataLi19}</li>
            </ul>

            <br /><br />

            <p style={{ fontSize: "18pt", backgroundColor: "rgb(229 229 229)", margin: "20pt 0pt", padding: "15pt" }}>
                {dataPartFour1} <br /> <br />
                {dataPartFour2} <br /> <br />
                {dataPartFour3} <br /> <br />
                {dataPartFour4} <br /> <br />
            </p>



            {isLargeScreen ? (
                <table>
                    <tr>
                        {/* <td><img src={project1_1} alt="porject1_1" width="300pt" height="300pt" /></td> */}
                        <td><img src={project1_2} alt="porject1_2" width="300pt" height="300pt" /></td>
                        <td><img src={project1_3} alt="porject1_3" width="300pt" height="300pt" /></td>
                        <td><img src={project1_4} alt="porject1_4" width="300pt" height="300pt" /></td>
                        <td><img src={project2_1} alt="porject2_1" width="300pt" height="300pt" /></td>
                        <td><img src={project2_2} alt="porject2_2" width="300pt" height="300pt" /></td>
                    </tr>
                </table>) : (
                <div style={{width:"90%"}} className='projectPic'>
                    {/* <img src={project1_1} alt="porject1_1" width="100%" /> */}
                    <img src={project1_2} alt="porject1_2" width="100%" />
                    <img src={project1_3} alt="porject1_3" width="100%" />
                    <img src={project1_4} alt="porject1_4" width="100%" />
                    <img src={project2_1} alt="porject2_1" width="100%" />
                    <img src={project2_2} alt="porject2_2" width="100%" />
                </div>
            )}

            <Link to='/'>
                <button type="button" class="btn btn-dark" style={{ float: "right" }}>חזרה לדף הבית</button>
            </Link>

        </div>
    );
}

export default Article2;