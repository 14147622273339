import React from 'react';

function Data(props) {

    const data1 = `עו"ד יצחק אדגו איצ'ו מייסד ובעלים של משרד עורכי דין יצחק אדגו איצ'ו, בוגר תואר ראשון במשפטים (LLB) ומנהל עסקים (BA), ותואר שני במנהל עסקים עם התמחות במימון (MBA).`;
    const data2 = `עו"ד יצחק אדגו איצ'ו צבר ניסיון רב כעובד במגזר הציבורי והפרטי, הן בבנק הגדול בישראל למשכנתאות וחברות ממשלתיות, והן בעבודה מול משרדי עורכי הדין הגדולים בישראל.`;
    const data3 = `במסגרת עבודתו נחשף לתחומים מגוונים בתחום האזרחי-מסחרי ובכלל זה, עריכת הסכמים מסחריים מגוונים, עריכת וניהול מכרזים בהיקפים גדולים ועבודה מול הרשויות. משכנתאות, נדל"ן ועוד.`;
    const data4 = `עו"ד יצחק אדגו איצ'ו שירת כלוחם קרבי בחטיבת גולני  ומשרת פעיל במילואים.`;
    const data5 = `רואה בהתנדבות ובתרומה לקהילה ערך עליון ובהמשך לכך, פעל שנים ארוכות בהתנדבות במסגרות שונות ("לתת", ארגון גמל"א, מדריך נוער בסיכון- בית אליעזר, חדרה).`;
    const data6 = `עו"ד יצחק אדגו איצ'ו משמש כפעיל חברתי בולט ואף נותן שירותים שונים במסגרת עבודת המשרד כפרו בונו למעוטי יכולת.`;

    return (
        <div className='Data'>
            <div className='aboutas'>
<br/>
<br/>
<br/>
                <p style={{ fontSize: "18pt" }}>
                    {data1} <br/> <br/>
                    {data2} <br/> <br/>
                    {data3} <br/> <br/>
                    {data4} <br/> <br/>
                    {data5} <br/> <br/>
                    {data6} <br/> <br/>
                </p>
            </div>
        </div>
    );
}

export default Data;