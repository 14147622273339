import React from 'react';
import { Link } from 'react-router-dom';

function Article3(props) {

    const data1 = `חוזים והסכמים משפטיים מהווים חלק בלתי נפרד משגרת חיינו. ישנם חוזים בעלי מאפיינים אישיים כגון הסכם ממון, חוזה מתנה, ועוד... וישנם חוזים בעלי מאפיינים כגון חוזה מכר, חוזה שכירות, חוזה הפצה, הסכם זכיינות וכו'..`;
    const data2 = `בשל עובדה זו, כמעט כל אדם בשלב זה או אחר בחייו נדרש לשירותי עורך דין הבקיא בדיני החוזים לשם ייעוץ בענייני חוזים, הפרת חוזה, בדיקת חוזה ועריכת חוזים והסכמים משפטים על מגוון סוגי החוזים המסחריים והאישיים`;
    const data3 = `חוזים והסכמים משפטיים מצריכים ידע בניסוח תוכן החוזה או ההסכם אותו אתם מבקשים לערוך. באופן בו הניסוח יהיה ברור ולא משתמע לשני פנים. ניסוח שאינו נכון או שמשתמע לשני פנים יש בו כדי להוביל למחלוקות בין הצדדים לחוזה לעניין פרשנות החוזה או הסכם אותו ערכתם. עורך דין לענייני חוזים מיומן ידע להגשים את מטרת החוזה באופן בו התוכן יהיה ברור, ענייני ושאינו משתמע לשני פנים דבר שיש בו כדי למנוע ו/או להפחית מחלוקת פרשנית  בין הצדדים לחוזה.`;
    const data4 = `משרדנו מעניק שירותי בדיקת חוזים והסכמים משפטיים, מתן חוות דעת בענייני חוזים וכן עריכת מגוון חוזים מסחריים כגון: חוזה מכר, חוזה זכיינות, הסכם שותפות, הסכם נותני שירותים, חוזה השכרה, הסכם העברת זכויות, הסכם סודיות, הסכם הלוואה ועוד.`;
    const data5 = `משרדנו מתמחה בעריכת חוזים והסכמים אישים כגון: צוואות, הסכם מתנה וכו' הם הסכמים בעלי אופי אישי המצריך רגישות וידע מעורך הדין בהגשמת רצון הצדדים או הצד בעל ההסכם, עורך דין חוזים מיומן בנושאי חוזים אישים כדוגמת הסכם ממון, צוואות וכדומה. יוכל לייעץ וכן לערוך את אותו הסכם/חוזה אותו תבקשו וזאת בהתאם לרצונכם ומשאלות לבכם. `;

    const dataLaywer1 = `ייעוץ משפטי מקיף בענייני חוזים והסכמים משפטים.`;
    const dataLaywer2 = `עריכת כל סוגי החוזים המסחריים כגון: הסכמי שכירות, הסכמי שותפות, מכר, זכייינות, וכיו"ב...`;
    const dataLaywer3 = `ייעוץ ועריכת חוזים אחידים והסכמי מתן שירותים.`;
    const dataLaywer4 = `ייצוג משפטי בהפרת חוזה, ביטול חוזה.`;
    const dataLaywer5 = `עורך דין חוזים והסכמים - ייעוץ משפטי בחוזים והסכמים משפטיים - מעורך דין לחוזים הבקיא בדיני החוזים והסכמים המשפטיים,
    הסכמי ממון וצוואות, ייעוץ בעריכת חוזים הסכמים מסחריים, הסכמי ממון וצוואות, ייעוץ משפטי בביטול חוזים והסכמים משפטיים.`;



    return (
        <div className='Article'>
            <Link to='/'>
                <button type="button" class="btn btn-dark" style={{ float: "right" }}>חזרה לדף הבית</button>
            </Link>

            <br />

            <h1>
                חוזים והסכמים
            </h1>
            <p style={{ fontSize: "18pt" }}>
                {data1} <br /> <br />
                {data2} <br /> <br />
                {data3} <br /> <br />
                <h3>אז מה עושים?</h3>
                {data4} <br /> <br />
                {data5} <br /> <br />
            </p>

            <ul>
                <li>{dataLaywer1}</li>
                <li>{dataLaywer2}</li>
                <li>{dataLaywer3}</li>
                <li>{dataLaywer4}</li>
                <li>{dataLaywer5}</li>
            </ul>

            <br />
            <br />
            <br />

            <Link to='/'>
                <button type="button" class="btn btn-dark" style={{ float: "right" }}>חזרה לדף הבית</button>
            </Link>
        </div>
    );
}

export default Article3;