import React, { useEffect, useState } from 'react';
import Box1 from './boxes/Box1';
import Box2 from './boxes/Box2';
import Box3 from './boxes/Box3';
import { Link } from 'react-router-dom';

function BoxArea(props) {
    const [isLargeScreen, setIsLargeScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 600);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='BoxArea'>

            {isLargeScreen ? (
                <>
                    <Link to="contractsAndAgreements"> <Box3 className="boxpadding" /> </Link>
                    <Link to="realEstate"> <Box1 className="boxpadding" /> </Link>
                    <Link to="urbanRenewal"> <Box2 /> </Link>
                </>
            ) : (
                <>
                    <Link to="urbanRenewal"> <Box2 /> </Link>
                    <Link to="realEstate"> <Box1 className="boxpadding" /> </Link>
                    <Link to="contractsAndAgreements"> <Box3 className="boxpadding" /> </Link>
                </>
            )}


        </div>
    );
}

export default BoxArea;