import React, { useEffect, useState } from 'react';
import backGroundHeader from '../photos/backGroundHeader.jpg'
import MobilebackGroundHeader from '../photos/MobilebackGroundHeader.jpg'


function Header(props) {
    const [isLargeScreen, setIsLargeScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 600);
        };

        handleResize(); 
        
        window.addEventListener('resize', handleResize); 

        return () => {
            window.removeEventListener('resize', handleResize); 
        };
    }, []);

    return (
        <div className='Header'>

            {isLargeScreen ? (
                <img src={backGroundHeader} alt="logo" width='100%' height='100%' />
            ) : (
                <img src={MobilebackGroundHeader} alt="logo" width='100%' height='100%' />
            )}

        </div>
    );
}

export default Header;