import React from 'react';
import { Link } from 'react-router-dom';

function Article1(props) {

    const data1 = `משרדנו מעניק מתן ליווי משפטי מקצועי והבטחת האינטרס של הלקוח בנושאים מגוונים לרבות קניית דירה יד שנייה ודירה מקבלן, קניית ומכירת מגרשים וקרקעות חקלאיות, ליווי פרויקטי התחדשות עירונית תמ"א 38, פינוי בינוי, ביצוע עסקאות קומבינציה, ייעוץ ומתן תחזית בנושאי מיסוי מקרקעין, העברה ללא תמורה, העברה בירושה, וכן עסקאות בנדל"ן המסחרי.`;
    const data2 = `כל עסקה בנדל"ן מחייבת בדיקות מקיפות ומלאות של הנכס על מנת שנוכל להבין את מצבו בכללותו. דוגמה נפוצה לכך היא בדיקה לגבי חריגות בנייה אך לא רק. עורך הדין בודק האם הנכס רשום בטאבו ובכלל זה את זהות הבעלים האמיתית, האם ישנם עיקולים או שיעבודים על הנכס, היבטי מס ועוד.`;
    const data3 = `כאמור, ביצוע עסקת נדל"ן דורשת  ביצוע שורה של פעולות בהן דיווח לרשויות על העסקה לצרכי מס. לכל לקוח מאפיינים ייחודיים לו שיכולים לבוא ביטוי גם בחבות במס (לדוגמה: קונה- מס רכישה, מוכר- מס שבח, לקוח בעל מספר נכסים, לקוחות פרטיים/עסקיים וכו'). איפיון נכון של הלקוח עוד בטרם ביצוע העסקה על ידי תכנון מס נכון, יכול להוביל לחיסכון של עשרות ומאות אלפי ש"ח. `;
    const data4 = `משרדנו מתאים את ההסכם לעסקה הספציפית תוך ביטוי מלוא רצונותיו של הלקוח על הצד הטוב ביותר. חשוב מאוד שההסכם ישקף את כל הנתונים האמיתיים של העסקה וההסכמות אליהן הגיעו הצדדים במהלך המשא ומתן, לדוג': מועדי תשלום ומסירה, הסרות שיעבודים, הערות אזהרה וכו'.
    כל עסקה בנדל"ן שונה ובעלת מרכיבים ספציפיים וייחודיים רק לה ולכן ניסוח לא מספק של ההסכם בין הצדדים עלול להוביל למחלוקות, ביטול העסקה ואף לתביעות בין הצדדים. תפקידו של עורך הדין הוא בין היתר, לזהות "נורות אדומות" במעלה הדרך (לפעמים ממש בהתחלה) ולהסירן. הדבר נכון גם לאנשים בעלי ניסיון רב בביצוע עסקאות בנדל"ן.`;
    const data5 = `ראשית חשוב להדגיש כי לקוח המעוניין ברכישת דירה מקבלן חייב להיעזר בעו"ד מטעמו ולא להסתפק בעורך הדין מטעם הקבלן משום שהאחרון דואג לאינטרס של הקבלן ולא דואג לרוכשים.`;
    const data6 = `למרות שההסכם אותו מציג הקבלן לרוכשים נראה לעיתים כעובדה גמורה, הניסיון מלמד כי ליווי עורך דין בעסקה יכול להוביל לשינויים רבים בהסכם תוך הבטחת האינטרס של הרוכש בלבד ולחסוך עוגמת נפש והרבה מאוד כסף. לדוגמה: בניין חדש באופן טבעי מתייצב בקרקע בשנה הראשונה לאחר הקמתו. כתוצאה מכך יכולים להיגרם נזקים לדירה למרות שהיא חדשה "מהניילונים" (סדקים, רטיבויות, שברי אריחים ועוד). שינויים בנוסח ההסכם הקיים יבטיחו את קבלת הדירה בצורה חלקה, יבטיחו אחריות מיטבית לליקויים עתידיים על ידי הקבלן ועוד.`;

    const dataLaywer1 = `משרדנו מעניק מעטפת משפטית מלאה ללקוח תוך מציאת פתרונות מדוייקים ויצירתיים. בשבילנו, כל לקוח הוא עולם ומלואו ולרוב מדובר בעסקה הגדולה ביותר בחייו ואנו מתייחסים לכך בחרדת קודש. אנו מתחייבים ללקוחותנו מתן שירות מקצועי ללא פשרות, ליווי צמוד, זמינות מלאה, שקיפות ואמינות.`;

    return (
        <div className='Article'>
            <Link to='/'>
                <button type="button" class="btn btn-dark" style={{ float: "right" }}>חזרה לדף הבית</button>
            </Link>
            <h1>
                    מקרקעין
            </h1>
            <p style={{ fontSize: "18pt" }}>
             
                {data1} <br /> <br />
                <h3>חשוב לבדוק</h3>
                {data2} <br /> <br />
                {data3} <br /> <br />
                <h3>אז מה עושים?</h3>
                {data4} <br /> <br />
                <h3>רכישת דירה מקבלן</h3>
                {data5} <br /> <br />
                {data6} <br /> <br />
            </p>

            {/* <u><h2>פעילות המשרד:</h2></u> */}

            <ul>
                <li>{dataLaywer1}</li>
            </ul>
            
            <br />
            <br />
            <br />

            <Link to='/'>
                <button type="button" class="btn btn-dark" style={{ float: "right" }}>חזרה לדף הבית</button>
            </Link>
        </div>
    );
}

export default Article1;