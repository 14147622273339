import React from 'react';
import box1 from '../../photos/box1.jpg'

function Box1(props) {
    return (
        <div className='Box'>
            <img src={box1} alt="logo" width='100%' height='100%' />
        </div>
    );
}

export default Box1;