import React from 'react';
import Data from './Data';
import About from './About';
import BoxArea from './BoxArea';

function Index(props) {
    return (
        <div>
            <About />
            <BoxArea />
            <Data />
        </div>
    );
}

export default Index;