import './App.css';
import Header from './components/Header';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Index from './components/Index';
import { Route, Routes } from 'react-router-dom';
import Article1 from './components/articles/Article1';
import Article2 from './components/articles/Article2';
import Article3 from './components/articles/Article3';



function App() {
  return (
    <div className="App">
      <Header />


      <Routes>
        <Route path='/' element={<Index />} />

        <Route path='/realEstate' element={<Article1 />} />
        <Route path='/urbanRenewal' element={<Article2 />} />
        <Route path='/contractsAndAgreements' element={<Article3 />} />

      </Routes>


      <Contact />
      <Footer />
    </div>
  );
}

export default App;
