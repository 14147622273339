import React from 'react';
import box2 from '../../photos/box2.jpg'

function Box(props) {
    return (
        <div className='Box'>
            <img src={box2} alt="logo" width='100%' height='100%' />
        </div>
    );
}

export default Box;