import React from 'react';

function About(props) {

    const data1 = `משרד עורכי דין יצחק אדגו איצ'ו מתמחה בתחום ההתחדשות העירונית והאזרחי-מסחרי על כל גווניו. משרדנו מספק שירותים מקיפים ומותאמים אישית לצרכים הייחודיים של לקוחותינו. אנו גאים בהצעת פתרונות יצירתיים ובהיותנו זמינים בכל עת לסייע ללקוחותינו. קהל הלקוחות שלנו כולל גם אנשים פרטיים וגם חברות`;
    const data2 = `במשרד עורכי הדין יצחק אדגו איצ'ו, אנו מציעים הכוונה וליווי מומחה לדיירים המעורבים בפרויקטים של התחדשות עירונית ברחבי הארץ, ומסייעים להם בכל שלב במחויבות בלתי מעורערת להשגת הצלחה.`;
    const data3 = `ערכי הליבה שלנו הם אמינות, שקיפות ומקצועיות המשמשים כעקרונות מנחים בפרקטיקה שלנו. אנו מייחסים חשיבות רבה להבנת לקוחותינו והדרישות הספציפיות שלהם, ומאפשרים לנו להעניק להם ייעוץ ושירותים משפטיים מותאמים ומדוייקים.`;
    return (
        <div className='Data' >
            <div className='aboutas'>
                <br/>
                <h1 style={{ }}>
                    אודות המשרד
                </h1>
                <p style={{ fontSize: "18pt" }}>
                    {data1} <br /> <br />
                    {data2} <br /> <br />
                    {data3} <br /> <br />
                </p>
            </div>
        </div>
    );
}

export default About;